import { types } from '@/types'
import { checkPasswordStrength } from '@/utils/functions/form'
import { useState } from 'react'

export const usePasswordStrength = () => {
  const [ passStrength, setPassStrength ] = useState(0)

  const handleStrength = e => {
    if (e.target.name === types.input.pass) {
      const strength = checkPasswordStrength(e.target.value)
      setPassStrength(strength)
    }
    return e.target.value
  }

  return [ passStrength, handleStrength ]
}
