import { apiCall } from "@/middleware/api"
import { config } from "@/middleware/config/config"
import { constants } from "@/utils/global"
import Cookies from "js-cookie"
import { signOut } from "next-auth/react"
import { recoverSaleOrder } from "../cart"
import { encodeHashJS } from "@/middleware/api/secure"

export const apiLogin = async({ username, password, ...props }) => {
  const saleOrder = await recoverSaleOrder()
  const encrypt = {
    username,
    password,
    saleOrder: saleOrder || null,
  }
  const sendData = {
    url: constants.URL.LOGIN,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)

  // !error && await Cookies.set(config.COOKIES._WLYR_ORDER, JSON.stringify({ saleOrder: message.saleOrder }))
  return {
    error,
    message
  }
}

export const apiRegister = async data => {
  const sendData = {
    url: constants.URL.REGISTER,
    encrypt: data,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  return {
    error,
    message
  }
}

export const apiRecoverPassword = async data => {
  const sendData = {
    url: process.env.NEXT_PUBLIC_RECOVER_PASSWORD,
    encrypt: data,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData, false)
  return {
    error,
    message
  }
}
export const checkHashPassword = async hash => {
  const sendData = {
    url: `${constants.URL.RECOVER_PASS_CHECK}?data=${hash}`,
    encrypt: ``,
    type: `GET`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const response = await apiCall(encriptedData)
  const { error, message } = await response
  return {
    error,
    message
  }
}

export const apiResetPassword = async data => {
  const sendData = {
    url: constants.URL.RECOVER_PASS_RESET,
    encrypt: data,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  return {
    error,
    message
  }
}

export const logOut = async(router, redirect = false) => {
  await signOut({ redirect: false })
  await router?.replace(!redirect ? process.env.NEXT_PUBLIC_LOGIN : router.asPath)
}

// export const recoverSaleOrder = () => {
//   const b = secure.decrypt(localStorage.getItem(config.COOKIES._WLYR_))
//   return b[types.preferences.login].saleOrder
// }
