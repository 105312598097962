/* eslint-disable default-param-last */
/* eslint-disable max-lines */

/* eslint-disable no-extra-parens */
import { validations } from "@/middleware/api"
import { types } from "@/types"
import parse from "html-react-parser"
import { signIn } from "next-auth/react"
import { BsCardHeading } from "react-icons/bs"
import {
  FaPhoneAlt,
  FaUserTag
} from "react-icons/fa"
import { IoIosContact, IoMdPerson, IoMdPersonAdd, IoMdSend } from "react-icons/io"
import { MdAlternateEmail, MdVisibility, MdVisibilityOff } from "react-icons/md"
import {
  apiLogin, apiRecoverPassword, apiRegister, apiResetPassword
} from "../login-password"

/**
 * Función encargada de construir el objeto de values para useForm
 * @param {*} element
 * @returns
 */
export const constructValuesForm = (element, vals = null, devInv = false) => {
  const tempValues = {}
  element.forEach((item, idx) => {
    if ([ types.input.dir.default_invoice_address, types.input.dir.default_shipping_address ].includes(item) && devInv) {
      tempValues[item] = {
        value: vals ? (vals[item] ? vals[item] : true) : true,
        error: false,
        type: item
      }
    } else {
      tempValues[item] = {
        value: vals ? vals[item] : ``,
        error: false,
        type: item
      }
    }
  })
  return tempValues
}

export const constructInputsForm = (element, vals = null, devInv = false) => {
  const value = element.map((item, idx) => ({ ...item }))
  return value
}

export const valSamePass = async(getValues, setError, clearErrors) => {
  const inputsPass = await getValues([ types.input.pass, types.input.recoverPass ])
  if (inputsPass[0] !== inputsPass[1] || inputsPass[0] === `` || inputsPass[1] === ``) {
    await [ types.input.pass, types.input.recoverPass ].map(x => setError(x, {
      type: x,
      message: ``
    }, { shouldFocus: false }))
    return false
  }
  await [ types.input.pass, types.input.recoverPass ].map(x => clearErrors(x))
  return true
}

export const validateForm = (
  reqValues, getValues, setError, errorTexts, isRegister = false
) => {
  const errVal = Object.entries(getValues).every((x, i) => {
    if (
      [
        types.input.dir.name,
        types.input.dir.email,
        types.input.dir.phone,
        types.input.dir.vatNumber,
        types.input.dir.country,
        types.input.dir.state,
        types.input.dir.district,
        types.input.dir.city,
        types.input.dir.town,
        types.input.dir.street,
        types.input.dir.number,
        types.input.dir.floor,
        types.input.dir.extra,
        types.input.dir.comment,
        types.input.dir.default_invoice_address,
        types.input.dir.default_invoice_address,
        types.input.email,
        types.input.userName,
        types.input.name,
        types.input.phone,
        types.input.pass,
        types.input.recoverPass,
        types.input.lastName,
        types.input.message,
        types.input.order,
      ].includes(x[0])
    ) {
      const tmp = reqValues.find(b => b.type === x[0])
      if (tmp?.required && (isRegister || ![
        types.input.dir.name, types.input.dir.vatNumber, types.input.dir.floor, types.input.dir.extra, types.input.dir.comment
      ].includes(x[0]))) {
        const temp = validations[x[0]](x[1], errorTexts)
        if (x[1] === `` || !!temp) {
          setError(x[0], {
            type: x[0],
            message: temp || ``
          }, { shouldFocus: false })
          return false
        }
      }
    }
    return true
  })

  return !errVal
}

export const validateContactForm = (
  reqValues, getValues, setError, texts, flag = true
) => {
  const errVal = Object.entries(getValues).every((x, i) => {
    if (![types.input.order].includes(x[0])) {
      const temp = validations[x[0]](x[1], texts)
      if (x[1] === `` || !!temp || (x[0] === `legal` && !x[1])) {
        flag && setError(x[0], {
          type: x[0],
          message: temp || ``
        }, { shouldFocus: false })
        return false
      }
    }
    return true
  })
  return !errVal
}
export const validateOtherForms = (
  reqValues, getValues, setError, texts, flag = true
) => {
  const errVal = Object.entries(getValues).every((x, i) => {
    const { required } = reqValues.find(b => b.type === x[0])
    const temp = validations[x[0]](x[1], texts)
    if (required && (x[1] === `` || !!temp || (x[0] === `legal` && !x[1]))) {
      flag && setError(x[0], {
        type: x[0],
        message: temp || ``
      }, { shouldFocus: false })
      return false
    }
    return true
  })
  return !errVal
}
export const validateOnChange = async(
  value, type, clearErrors, setError, setFocus, texts
) => {
  const msg = await validations[type](value, texts)
  if (msg) {
    await setError(type, {
      type,
      message: msg
    })
  } else {
    await clearErrors(type)

    // await setFocus(type, { shouldFocus: true })
  }
}

export const inputsTypes = (
  prop, type, name, visibilityPass, visibilityRecoverPass
) => {
  const info = {
    inputType: type,
    name,
  }
  switch (prop) {
  case types.input.pass:
    info.inputType = visibilityPass ? `text` : `password`
    break
  case types.input.recoverPass:
    info.inputType = visibilityRecoverPass ? `text` : `password`
    break

  default:
  }
  return info
}

export const Icons = (props, flag = false) => {
  switch (props) {
  case types.input.user:
    return IoMdPerson
  case types.input.userName:
    return IoIosContact
  case types.input.vatNumber:
    return BsCardHeading
  case types.input.phone:
    return FaPhoneAlt
  case types.input.email:
    return MdAlternateEmail
  case types.input.pass:
  case types.input.recoverPass:
    return !flag ? MdVisibilityOff : MdVisibility
  case types.input.send:
    return IoMdSend
  case types.input.name:
    return IoMdPerson
  case types.input.attn:
    return FaUserTag
  case types.input.lastName:
    return IoMdPersonAdd
  default:
    break
  }
}

export const errorMsg = (x, lang) => {
  let msg = ``
  switch (true) {
  case x.level5 !== undefined:
    msg = parse()
    break
  case x.level4 !== undefined:
    msg = parse(lang[x])
    break
  case x.level3 !== undefined:
    msg = parse(lang[x])
    break
  default:
    msg = parse(lang[x])
    break
  }
  return msg
}

const sessionIn = async(
  { error, message }, router, callback, texts, setDis, setErrorToastify, flag = true
) => {
  if (!error && flag) {
    const msg = message
    msg.redirect = false
    await signIn(`credentials`, msg).then(res => router?.push && router?.push(callback === process.env.NEXT_PUBLIC_CART ? `${callback}?l=true` :
      callback, callback))
  } else {
    await setDis(false)
    await setErrorToastify({
      error,
      message: texts[message]
    })
    !flag && (await router?.replace(callback))
  }
}

export const loginIn = async(
  setDis, data, router, callback, texts, setErrorToastify, cartConnectif = null, hostname
) => {
  await setDis(true)
  const { error, message } = await apiLogin(data)
  await sessionIn(
    {
      error,
      message
    }, router, callback, texts, setDis, setErrorToastify
  )
}
export const registerIn = async(
  setDis, data, router, callback, texts, setErrorToastify, cartConnectif = null, hostname
) => {
  await setDis(true)
  const { error, message } = await apiRegister(data)
  await sessionIn(
    {
      error,
      message
    }, router, callback, texts, setDis, setErrorToastify
  )
}
export const recoverPassIn = async(
  setDis, data, texts, setErrorToastify, setShow
) => {
  await setDis(true)
  const { error, message } = await apiRecoverPassword(data)
  if (!error) {
    setShow(true)
  } else {
    await setDis(false)
    await setErrorToastify({
      error,
      message: texts[message]
    })
  }
}
export const changePassIn = async(
  setDis, data, router, callback, texts, setErrorToastify
) => {
  await setDis(true)
  const { error, message } = await apiResetPassword(data)
  await sessionIn(
    {
      error,
      message
    }, router, callback, texts, setDis, setErrorToastify, false
  )
}

export const getAcLabel = (option, input) => {
  switch (input) {
  case `zip`:
    return `${option?.postal_code || option}`
  case `state`:
  case `district`:
  case `city`:
    return `${option?.label || option.name}`
  case `town`:
    return `${option?.label || option}`
  case `category`:
    return `${option?.subject}`
  case `ticket`:
    return `${option?.reference}`
  case `order`:
    return `ref: ${option?.draft} ${option?.order ? ` | order: ${option?.order})` : ``} - ${option?.date}`
  default:
    return `${option?.name}`
  }
}

export const getAcValue = (value, opts) => {
  let retOpt = opts
  if (opts?.length > 1 && value) {
    retOpt = opts.filter((op, idx) => op.search === value || op.postal_code === value)
  }

  return retOpt && opts?.length === 1 ? retOpt[0] : undefined
}

export const checkPasswordStrength = password => {
  const hasData = password.length > 0
  if (!hasData) {
    return 0
  }
  const shortPass = password.length >= 6
  const longPass = password.length >= 15
  const hasLowerCase = /[a-z]/.test(password)
  const hasUpperCase = /[A-Z]/.test(password)
  const hasNumbers = /[0-9]/.test(password)
  const hasSpecialChars = /[^a-zA-Z0-9]/.test(password)
  const differentSpecialChars = new Set(password.match(/[^a-zA-Z0-9]/g))
  const hasDifferentSpecialChars = differentSpecialChars.size > 2

  // ? strong condition
  const strongPasss = hasLowerCase && hasUpperCase && hasNumbers && hasDifferentSpecialChars

  let strength = 1 // ## very weak

  if (shortPass) {
    if ([
      hasLowerCase, hasUpperCase, hasNumbers, hasSpecialChars
    ].filter(Boolean).length >= 2) {
      strength = 2 // ## weak
    }
    if (strongPasss) {
      strength = 3 // ## strong
    }
    if (strongPasss && longPass) {
      strength = 4 // ## very strong
    }
  }

  return strength
}
