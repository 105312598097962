import { Tooltip } from "@mui/material"
import { MdInfo } from "react-icons/md"

export const PasswordStrength = ({ passStrength, className, texts = null, ...props }) => {
  const { password_strength_tooltip, password_strength } = texts
  const veryWeak = passStrength === 1 ? `bg-red-500` : passStrength === 2 ? `bg-orange-400` : passStrength === 3 ? `bg-yellow-400` : passStrength > 3 ? `bg-green-400` : `bg-neutral-200`
  const weak = passStrength === 2 ? `bg-orange-400` : passStrength === 3 ? `bg-yellow-400` : passStrength > 3 ? `bg-green-400` : `bg-neutral-200`
  const strong = passStrength === 3 ? `bg-yellow-400` : passStrength > 3 ? `bg-green-400` : `bg-neutral-200`
  const veryStrong = passStrength > 3 ? `bg-green-400` : `bg-neutral-200`

  // TODO - textos de fuerza de contraseña
  const ToolTipContent = () => (
    <ul className="rounded-md">
      {password_strength_tooltip.map((text, i) => (
        <li key={i} >
          <p className="text-xs text-white">{text}</p>
        </li>
      ))}
    </ul>
  )

  return (
    <div className={`${className} mt-1 grid grid-cols-4 px-4 gap-[2px] `} {...props}>
      {!!(texts && passStrength) &&
        <div className="flex col-span-4 items-center">
          <Tooltip title={<ToolTipContent/>} arrow>
            <MdInfo className="text-neutral-400 mr-1"/>
          </Tooltip>
          <p className="text-xs leading-3">{password_strength[passStrength - 1]}</p>
        </div>
      }
      <div className={`h-1 ${veryWeak}`}/>
      <div className={`h-1 ${weak}`}/>
      <div className={`h-1 ${strong}`}/>
      <div className={`h-1 ${veryStrong}`}/>
    </div>
  )
}
