import { forwardRef } from 'react'

const TWInput = forwardRef(({
  children, className = ``, placeholder, style, colors, color, bg, inputbg, hvr, border, inputborder, inputType = `base`, starticon, endicon, size = `22`, clickicon, clickendicon, error, onClick, ...props
}, ref) => {
  const { required, disabled } = props
  const StartIcon = starticon
  const EndIcon = endicon
  const obj = {
    base: {
      inputType: `input-base`,
      color: disabled ? colors.darkGrey2 : color ?? colors.secondary,
      border: border ?? `transparent`,
      inputborder: inputborder ?? `transparent`,
    },
  }
  return (
    <div className={className}>
      <div {...{ onClick }} style={{ backgroundColor: bg ?? colors.lightGrey, border: `1px solid ${obj[inputType].border}`, boxShadow: error && `0px 0px 6px 2px #ff0000` }} className={`rounded-full flex items-center justify-start`}>
        {/* // ? START ICON */}
        {starticon && <StartIcon id="start-icon-tw-input" onClick={clickicon} style={{ color: obj[inputType].color, width: `${size}px`, height: `${size}px` }} className={`ml-3 mr-1`} />}

        {/* // ? INPUT */}
        <input {...props}
          placeholder={`${placeholder} ${required ? `*` : ``}`}
          ref={ref}
          style={{ "--bg": inputbg ?? `transparent`, "--color": obj[inputType].color, '--border': obj[inputType].inputborder }} {...props}
          className={`${obj[inputType].inputType}`}/>

        {/* // ? END ICON */}
        {endicon && <div id="end-icon-tw-input" onClick={clickendicon ?? onClick} className={`mr-3 ml-1 ${clickendicon ? `cursor-pointer` : ``}`}>
          <EndIcon {...props} style={{ color: obj[inputType].color, width: `${size}px`, height: `${size}px` }} />
        </div>
        }
        {children}
      </div>
      {error && <p className={`text-error ml-4 mt-1`}>{error}</p>}
    </div>
  )
})

export default TWInput
