/* eslint-disable no-undef */
import { ToastifyContext } from "@/context"
import { apiCall } from "@/middleware/api"
import { types } from "@/types"
import {
  changePassIn, loginIn, recoverPassIn, registerIn
} from "@/utils/functions/form"
import { constants } from "@/utils/global"
import { useRouter } from "next/navigation"
import { useContext, useState } from "react"

export const useRecaptcha = props => {
  const [ captcha, setCaptcha ] = useState(false)
  const [ charge, setCharge ] = useState(true)
  const { setErrorToastify } = useContext(ToastifyContext)
  const router = useRouter()

  const handleRecaptcha = async token => {
    const sendData = {
      url: constants.URL.RECAPTCHA,
      encrypt: { token },
      type: `POST`
    }
    const { error, message } = await apiCall(
      sendData, undefined, undefined, undefined, false
    )
    return message
  }
  const callReCaptcha = ({
    type, setCheckedCaptcha, setDis, texts, data, setShow, referer, cartConnectif = null, hostname, ...prop
  }) => {
    // const callReCaptcha = ({ type, data, setCheckedCaptcha, route, disabled, show, defaultRoute, cartOptions, ...prop }) => {
    grecaptcha.ready(() => {
      grecaptcha.execute(process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC, { action: `submit` }).then(async token => {
        const result = await handleRecaptcha(token)
        const { success, score } = await result
        // eslint-disable-next-line no-extra-parens
        if ((!!score && score <= 0.2) || !success) {
          await setCaptcha(true)
          await setCheckedCaptcha(false)
        } else {
          await setDis(true)
          switch (type) {
          case types.preferences.login:
            await loginIn(
              setDis, data, router, referer, texts, setErrorToastify, null, hostname
            )
            break
          case types.preferences.register:
            await registerIn(
              setDis, data, router, referer, texts, setErrorToastify, null, hostname
            )
            break
          case types.preferences.recoverPass:
            await recoverPassIn(
              setDis, data, texts, setErrorToastify, setShow
            )
            break
          case types.preferences.resetPass:
            await changePassIn(
              setDis, data, router, process.env.NEXT_PUBLIC_LOGIN, texts, setErrorToastify
            )
            break

          default:
            break
          }
          await setCaptcha(false)
        }
        await setCharge(false)
      })
    })
  }

  return {
    charge,
    captcha,
    setCaptcha,
    callReCaptcha
  }
}
