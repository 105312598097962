'use client'
import { constructInputsForm, validateForm } from "@/utils/functions/form"
import { useEffect, useMemo, useState } from "react"
import { useEffectOnce } from "./useEffectOnce"

// customHook para input's del formulario

export const useConstruct = values => {
  const valueForm = useMemo(() => constructInputsForm(values))
  return valueForm
}

export const useFormStates = () => {
  const [ dis, setDis ] = useState(true)
  const [ checkedCaptcha, setCheckedCaptcha ] = useState(true)
  const [ checked, setChecked ] = useState(false)
  const handleCheck = e => {
    setChecked(!checked)
  }

  useEffect(() => {
    setDis(!checkedCaptcha)
  }, [checkedCaptcha])
  return {
    dis,
    setDis,
    checkedCaptcha,
    setCheckedCaptcha,
    checked,
    handleCheck
  }
}

export const useFormAccount = form => {
  const [ text, setText ] = useState()
  const [ dis, setDis ] = useState(false)
  useEffect(() => {
    setText(form)
  }, [form])

  return {
    text,
    dis,
    setDis
  }
}
export const useFormAddr = (
  values, getValues, setError, watch, texts
) => {
  const [ dis, setDis ] = useState(true)

  useEffectOnce(() => {
    setDis(validateForm(
      values, getValues(), setError, texts, false
    ))
  }, [])
  useEffect(() => {
    watch((value, { name, type }) => setDis(validateForm(
      values, value, setError, texts, false
    )))
  }, [watch])
  return {
    dis,
    setDis
  }
}
